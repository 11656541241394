import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

function Banner(data) {
    return (
        <div className="how-it-works flex flex-col lg:flex-row -mb-20 2xl:-mb-24">
            <div className="shape-one bg-blue-200 w-full lg:w-7/12 pt-16 lg:pt-32 pb-12 lg:pb-24 z-10 px-3 md:px-6">
                <div className="w-full lg:w-9/12 xl:w-8/12 2xl:w-7/12 lg:ml-6 xl:ml-20 2xl:ml-32">
                    <div className="flex">
                        <div className="text-center lg:text-left">
                            <h2 className="relative how-it-works-title text-white font-medium text-4xl lg:text-5xl mb-6">
                                {data.data.howItWorksBannerTitle}
                            </h2>
                            <p className="text-sm lg:text-base leading-snug lg:leading-normal font-normal text-white mb-8">
                                {data.data.howItWorksBannerText}
                            </p>
                                <Link to={data.data.howItWorksBannerButtonLink} className="flex w-max bg-white hover:bg-transparent rounded-full px-10 py-3 text-blue-400 hover:text-white text-xs font-semibold mx-auto lg:ml-0 border-2 border-white">
                                    {data.data.howItWorksBannerButtonText}
                                </Link>
                             
                            <div className="flex justify-center lg:justify-start">
                                <p className="relative how-it-works-cta text-white text-xs pt-2 mt-6"><span className="opacity-60">{data.data.howItWorksBannerTextBottom}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-8/12 shape-two lg:-ml-40 h-96 lg:h-auto -mt-20 lg:mt-0 2xl:max-h-100">
                <div className="h-full w-full">
                    <Img fluid={data.data.howItWorksBannerImage.localFile.childImageSharp.fluid} alt={data.data.howItWorksBannerImage.altText} className="w-full h-full object-cover object-fit mb-0" />
                </div>
            </div>
        </div>
    );
}

export default Banner;