import React from "react"
import Parser from "html-react-parser"
import { Link } from "gatsby"
import Apple from "../../svg/apple-store.svg"
import GoogleStore from "../../svg/google-play-store.svg"

function Videos(data) {
  console.log(data)
  return (
    <div className="container px-3 md:px-6">
      <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
        <h2 className="text-4xl lg:text-6xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-12 ">
          {data.data.howItWorksTopSectionTitle}
        </h2>
        <p className="text-gray-600  text-sm-base lg:text-base leading-normal font-medium text-center mb-0">
          {data.data.howItWorksTopSectionText}
        </p>
      </div>
      <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10">
        <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
        <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
      </div>
      <div className="flex flex-col lg:flex-row text-center mt-12 mb-8">
        <div className="w-full lg:w-1/2 lg:px-5 mb-6">
          <div className="lg:px-4">
            <h3 className="text-blue-400 text-2xl font-medium mb-2">
              {data.data.howItWorksTopSectionService1Title}
            </h3>
            <p className="text-gray-600 leading-normal text-xs mb-5">
              {data.data.howItWorksTopSectionService1Text}
            </p>
          </div>
          {data.data.howItWorksTopSectionService1VideoLink ? (
            <div className="w-full">
              <div className="container-video relative w-full h-0">
                {Parser(data.data.howItWorksTopSectionService1VideoLink)}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex mx-auto w-fit-content my-8">
            <div className="mr-5 cursor-pointer">
              <div className="w-24">
                <div className="flex  bg-opacity-10 rounded-md p-1 -mt-4  gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi text-black mt-1 bi-apple"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                  </svg>
                  <span style={{ fontSize: 12 }} className=" font-semibold">
                    App Store
                  </span>
                </div>
                <img
                  src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fmanus%2Fid1578523285&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
                  alt="qr code"
                />
              </div>
            </div>
            <div className="cursor-pointer">
              <div className="w-24">
                <div className="flex p-1 -mt-4 gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi mt-1.5 bi-google-play"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27" />
                  </svg>
                  <span style={{ fontSize: 12 }} className=" font-semibold">
                    Play Store
                  </span>
                </div>
                <img
                  src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.manusapp.manusapp&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
                  alt="qr code"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 lg:px-5 mb-6">
          <div className="lg:px-4">
            <h3 className="text-blue-400 text-2xl font-medium mb-2">
              {data.data.howItWorksTopSectionService2Title}
            </h3>
            <p className="text-gray-600 leading-normal text-xs mb-5">
              {data.data.howItWorksTopSectionService2Text}
            </p>
          </div>
          {data.data.howItWorksTopSectionService2VideoLink ? (
            <div className="w-full">
              <div className="container-video relative w-full h-0">
                {Parser(data.data.howItWorksTopSectionService2VideoLink)}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex mx-auto w-fit-content my-8">
            <div className="mr-5 cursor-pointer">
              <div className="w-24">
                <div className="flex  bg-opacity-10 rounded-md p-1 -mt-4  gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi text-black mt-1 bi-apple"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                  </svg>
                  <span style={{ fontSize: 12 }} className=" font-semibold">
                    App Store
                  </span>
                </div>
                <img
                  src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fmanus-work%2Fid1578515107&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
                  alt="qr code"
                />
              </div>
            </div>
            <div className="cursor-pointer">
              <div className="w-24">
                <div className="flex p-1 -mt-4 gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi mt-1.5 bi-google-play"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27" />
                  </svg>
                  <span style={{ fontSize: 12 }} className=" font-semibold">
                    Play Store
                  </span>
                </div>
                <img
                  src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.manustradespeople.manustradespeople&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
                  alt="qr code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Videos
