import Faq from "react-faq-component";
import React from "react";
import Variation from "./variation";

const styles = {
    titleTextColor: "#1c91e3",
    rowTitleColor: "#1c91e3",
    rowContentColor: '#464545',
    arrowColor: '#464545'
};

function renderFaq(data) {
    var faq = [];
    data.faq.forEach(element => {
        faq.push({
            title: element.node.title,
            content: element.node.content
        })
    });
    const dt = { 'rows': faq}
   
    return (
        <div className="container px-3 md:px-6 mt-32 pb-16" id="faq">
          
            <h2 className="text-blue-400 text-center font-medium text-4xl lg:text-5xl mb-2 lg:mb-4 mlg:leading-snug">Frequently Asked Questions</h2>
            <Variation data={dt} styles={styles} />
          
        </div>
    );
}

export default renderFaq;