import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import InvestorPopup from "../components/common/investorPopup"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Videos from "../components/how-it-works/videos"
import Banner from "../components/how-it-works/banner"
import FAQ from "../components/how-it-works/faq"
import "../css/how-it-works.css"

const HowItWorks = () => {
  const howItWorksSections = useStaticQuery(graphql`
    query HowItWorks {
      allWpPage(filter: { id: { eq: "cG9zdDoxNTc=" } }) {
        edges {
          node {
            title
            HowItWorksTopSection {
              howItWorksTopSectionService1Text
              howItWorksTopSectionService1Title
              howItWorksTopSectionService1VideoLink
              howItWorksTopSectionService1AppleLink
              howItWorksTopSectionService1AndroidLink
              howItWorksTopSectionService2Text
              howItWorksTopSectionService2Title
              howItWorksTopSectionService2VideoLink
              howItWorksTopSectionService2AppleLink
              howItWorksTopSectionService2AndroidLink
              howItWorksTopSectionText
              howItWorksTopSectionTitle
            }
            HowItWorksBanner {
              howItWorksBannerButtonLink
              howItWorksBannerButtonText
              howItWorksBannerText
              howItWorksBannerTextBottom
              howItWorksBannerTitle
              howItWorksBannerImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      allWpFaq(
        filter: { status: { eq: "publish" } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)

  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])

  return (
    <Layout>
      <SEO
        title="How it works"
        description={
          howItWorksSections.allWpPage.edges[0].node.HowItWorksBanner
            .howItWorksBannerText
        }
      />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container how-it-works pt-20 lg:pt-24">
        <Videos
          data={howItWorksSections.allWpPage.edges[0].node.HowItWorksTopSection}
        />
        <Banner
          data={howItWorksSections.allWpPage.edges[0].node.HowItWorksBanner}
        />
        <FAQ faq={howItWorksSections.allWpFaq.edges} />
        {investPopup ? (
          <InvestorPopup setInvestPopup={setInvestPopup} />
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

export default HowItWorks
