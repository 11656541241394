export const CUSTOMER_DATA = {
    rows: [
      {
        title: "Why should I use Manus?",
        content:
          "<p>Manus is an innovative marketplace that connects consumers with vetted tradespeople for home tasks and repairs, offering a transparent, fixed hourly rate and 24/7 availability. It simplifies the process, removing the need for bids or subscriptions, and revolutionises the traditional DIY and maintenance. The “Uber” for Tradespeople.         </p>\n",
      },
      {
        title: "How do you vet tradespeople?",
        content:
          "<p>We contact and check the credentials of all tradespeople who sign up to Manus. Checks include photo ID, trade body certifications and public liability insurance. We manually verify a minimum of 3 references for all our tradespeople.</p>\n",
      },
      {
        title: "How quickly can I expect to receive quotes?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">There is no quote to be received. As you’re hiring the tradesperson on a fixed hourly rate. The same hourly rate if you hire a Plumber, Electrician, Handyperson, Carpenter, Paint and Decorator and Roofer. </div>\n',
      },
      {
        title: "Do Manus tradespeople have insurance?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">Yes, all of our tradespeople  have insurance and has been vetted by us.         </div>\n',
      },
      {
        title: "How does Manus guarantee the work?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">If there`s anything wrong with the job, let us know within 72 hours and we`ll come back. If the problem’s to do with our work, we`ll fix it for free.</div>\n',
      },
      {
        title : "What are your business hours?",
        content: "<p>365 day a year, 24/7 depending on real time availability of each tradesperson.</p>"
      },
      {
        title : "What are my options if I don’t like quality of the work?",
        content: "<p>We have a rating system in which you can leave a rating and a description of your opinions, you can also get into contact with us and we will investigate thoroughly.</p>"
      },
      {
        title : "Can I hire more than one tradesperson?",
        content: "<p>Yes, if you need 6 different tradespeople then so be it,  no one can stop you :)</p>"
      },
      {
        title : "Is there a call out charge?",
        content: "<p>No, everything is covered by the first hour charge.</p>"
      },
      {
        title : "Is Manus app free to download and use?",
        content: "<p>Yes, free to download and use with no in app purchases, subscriptions or ads. Available on the App Store and Google Play Store.</p>"
      },
      {
        title : "Can my tradesperson do more than one job in a visit?",
        content: "<p>Absolutely, because of the hourly fee if you need something else within the house that needs to be done  the tradesperson would be more than happy to accommodate.</p>"
      }
    ],
  }

  export const TRADESPEOPLE_DATA = {
    rows: [
      {
        title: "How can I join Manus as a tradesperson?",
        content:
          "<p>Simply in 4 steps, Step 1 download the Manus Work on either on iOS or Android. Step 2 input your details, which will take less than a minute. Step 3 Upload your documents, don’t worry it will be anything to stressful or annoying. Step 4 Start accepting jobs. </p>\n",
      },
      {
        title: "Why should I join Manus App rather then?",
        content:
          "<p>Work when you want where you want with Manus Work. No need to give a quote or rack your brain if the customer will accept, the set fee revolutionises the outdated process you once knew.</p>\n",
      },
      {
        title: "Is there a fee to join Manus as a tradesperson?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">No ,there is absolutely no fee to join Manus just sign up and get ready to work when you want.</div>\n',
      },
      {
        title: "How many jobs can I complete in a day?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">However many you want day or night, Manus Work is here for you.</div>\n',
      },
      {
        title: "How far can I work?",
        content:
          '<div class="docos-replyview-body docos-anchoredreplyview-body" dir="ltr">Within the 20 miles from the location you make yourself available on Manus Work.</div>\n',
      },
      {
        title : "What happens if a customer refuses to end a job?",
        content: "<p>If the customer is not willing to scan the QR code to end the job, then please contact us though Support within the app and we will take it from there.In day, you can do as many jobs as you feel comfortable doing, no need to worry about a limit</p>"
      },
      {
        title : "How many trades can I register for?",
        content: "<p>So your a Jack of all trades I see, no problem as long as you have the proper experience and documentation to prove your qualifications feel free to choose any trades that appeals to you.</p>"
      },
      {
        title : "What happens if a job gets cancelled?",
        content: "<p>No worries, if the customer has cancelled after you have accepted a job you will be compensated a cancellation fee and will be made available to other customers immediately.</p>"
      },
      {
        title : "What happens if no one answers the door?",
        content: "<p>Thats a shame, if that does happen we do have an in app calling feature in which you can get into contact with the customer and if they can't allow you into the property they should then cancel the job. A compensated a cancellation fee and will be made available to other customers immediately.</p>"
      },
      {
        title : "What happens if I cannot find the customer address? ",
        content: "<p>Try and call the customer with our in app calling features. If that does work, contact us though Support within the app.</p>"
      },
      {
        title : "I have finished the job in less than an hour, what should I do?",
        content: "<p>That’s great, see if the customers needs any further help. Of course the clock is still ticking so the customer will be still be charged as long as you’re there.</p>"
      }
    ],
  }