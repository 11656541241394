import React, { useState } from "react"
import Faq from "react-faq-component"
import { CUSTOMER_DATA, TRADESPEOPLE_DATA } from "../../data/faqData"

const VARIATIONS = [
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi my-auto bi-person-raised-hand"
        viewBox="0 0 16 16"
      >
        <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a1 1 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207" />
        <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
      </svg>
    ),
    name: "Customer",
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi my-auto bi-layers"
        viewBox="0 0 16 16"
      >
        <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0zM8 9.433 1.562 6 8 2.567 14.438 6z" />
      </svg>
    ),
    name: "Tradespeople",
  },
]



export default function Variation({ data, styles }) {
  const [activedVariation, setActivedVariation] = useState(0)

  return (
    <>
      <div
        style={{ width: "fit-content" }}
        className="flex mx-auto select-none  mt-8 bg-gray-50  p-2 rounded-full  gap-2"
      >
        {VARIATIONS.map((val, key) => {
          return (
            <div
              key={`variation-${key}`}
              onClick={() => setActivedVariation(key)}
              className={`px-6 whitespace-nowrap animation-all duration-200 border ${
                activedVariation === key
                  ? " shadow-md hover:bg-opacity-15 border-blue-400 border-opacity-40 bg-white text-blue-600"
                  : "border-transparent  hover:bg-gray-100 hover:bg-opacity-20"
              } flex cursor-pointer  gap-2 font-medium py-3 rounded-full `}
            >
              {val.svg} {val.name}
            </div>
          )
        })}
      </div>
      <div className="mt-4 w-fit mx-auto">
        <Faq data={activedVariation === 0 ? CUSTOMER_DATA : TRADESPEOPLE_DATA} styles={styles} />
      </div>
    </>
  )
}
